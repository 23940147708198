<template>
	<el-drawer title="福袋详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc" v-if="isDrawer">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">福袋详情</div>
				<i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y">
				<t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4"
					@handleEvent="handleEvent" class="drawer_form">
					<!-- 平台自定义插槽 -->
					<template #lbpDetailList>
						<div class="pos-s t-0 bgc-fff zi-10">
							<div class="pb-20 pl-40 flex flex-ac flex-jb">
								<div class="fs-14 fw-bold flex-1">
									<span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
									<span>系列商品</span>
								</div>
								<div class="flex flex-ac" v-if="show" >
									<div class="mr-30">数量：{{formOpts.formData.lbpDetailList.length}}</div>
									<el-button type="primary" @click="addDomain">新增</el-button>
								</div>
							</div>
						</div>
						<div v-for="(domains,index) in formOpts.formData.lbpDetailList" class="pl-40 flex flex-ac mb-20"
							:key="index">
							<el-checkbox v-model="domains.isPresell" :true-label="1" :false-label="2">是否预售</el-checkbox>
							<el-form-item :label="'商品名称'" label-position="right"
								:prop="'lbpDetailList.' + index + '.ductName'"
								:rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.ductName"></el-input>
							</el-form-item>
							<el-form-item label="商品等级" label-position="right" :prop="'lbpDetailList.' + index + '.gradeConsumeId'"
							  :rules="{required: true, message: '请至少选择一个商品等级', trigger: 'blur'}" class="flex flex-ac"
							  v-if="!testFlag">
							  <el-select v-model="domains.gradeConsumeId" clearable placeholder="请选择">
							    <el-option :key="12" label="终赏" :value="12" v-if="domains.levelType == 12"></el-option>
							    <el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id" v-else>
							    </el-option>
							  </el-select>
							</el-form-item>	
							<!-- <el-form-item label="是否预售" label-position="right" el-form-item 
								:prop="'lbpDetailList.' + index + '.isPresell'"
								:rules="{required: true, message: '请至少选择一个商品等级', trigger: 'change'}"
								class="flex flex-ac" v-if="!testFlag">
								<el-select v-model="domains.isPresell" clearable placeholder="请选择" :disabled="!show">
									<el-option v-for="item in formOpts.listTypeInfo.isPresells" :key="item.value"
										:label="item.key" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item label="库存" label-position="right"
								:prop="'lbpDetailList.' + index + '.inventory'"
								:rules="{required: true, message: '库存数量不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.inventory" :disabled="!show"></el-input>
							</el-form-item>
							<el-form-item label="参考价格" label-position="right"
								:prop="'lbpDetailList.' + index + '.referPrice'"
								:rules="{required: true, message: '参考价格不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.referPrice"></el-input>
							</el-form-item>
						<!-- 	<el-form-item label="参考等级" label-position="right"
								:prop="'lbpDetailList.' + index + '.levelType'"
								:rules="{required: true, message: '请至少选择一个商品等级', trigger: 'change'}"
								class="flex flex-ac" v-if="!testFlag">
								<el-select v-model="domains.levelType" clearable placeholder="请选择" :disabled="!show">
									<el-option v-for="item in formOpts.listTypeInfo.levelTypes" :key="item.value"
										:label="item.key" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item label="参考等级" label-position="right" :prop="'lbpDetailList.' + index + '.levelType'"
							  :rules="{required: true, message: '请至少选择一个参考等级', trigger: 'change'}" class="flex flex-ac">
							  <el-select v-model="domains.levelType" clearable placeholder="请选择">
							    <el-option :key="12" label="LAST" :value="12" v-if="domains.gradeConsumeId == 12"></el-option>
							   <el-option v-for="item in formOpts.listTypeInfo.levelTypes" :key="item.value" :label="item.key" :value="item.value" v-else>
							    </el-option>
							  </el-select>
							</el-form-item>
							<el-form-item label="商品图片" label-position="right"
								:prop="'lbpDetailList.' + index + '.boxImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.boxImg"></upload-file>
							</el-form-item>
							<el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
								v-if="formOpts.formData.lbpDetailList.length > 1">删除
							</el-button>
						</div>
					</template>
					<!-- 上传图片 -->
					<template #uploadFile="data">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="26"
							v-model="formOpts.formData[data.value]"></upload-file>
					</template>
				</t-simple-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" close="cancel" width="30%" append-to-body>
			<span>您确认展示{{formOpts.formData.showPage}}套吗？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="editOkSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</el-drawer>
</template>

<script>
	export default {
		name: 'luckyDetails',
		components: {
			"TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				dialogVisible: false, //确认展示套数，提示弹框
				goodId: '', //详情id
				isDrawer: false,
				butLoading: false,
				gradeConsumes: [], //真实等级
				formOpts: {
					labelPosition: 'top',
					ref: null,
					formData: {
						coverImg: "", //	封面图
						dateDay: 365, //	优惠金面额
						deliverDate: "", //发货日期
						id:'',
						lbpDetailList: [{
							boxImg: "", //商品图片
							ductName: "", //商品图片
							gradeConsumeId:'', //实际等级
							id:'', //
							inventory: 0, //库存
							isPresell:2, //1 预售 2现货
							levelType:12, //前端展示等级
							othActSeriesId: 0, //
							referPrice: 0, //参考价格
						}],
						// luckyNum: 0, //连抽次数
						money: 0, //回收可得优惠金面额
						note: "", //	玩法说明
						othActType: 0, //活动系列类型
						price: 0, //原价
						recycleBlance: 0, //回收可得余额
						seriesName: "", //系列名称
						shelves: '', //	上下架
						showPage: 0, //显示套数
						soldPrice: 0, //显示套数
						totalPage: 10, //总套数
					},
					fieldList: [{
							label: '系列名称',
							value: 'seriesName',
							comp: 'el-input'
						},
						// {
						// 	label: '分类',
						// 	value: 'othActType',
						// 	type: 'select-arr',
						// 	comp: 'el-select',
						// 	list: 'seriesTypes',
						// 	bind: {
						// 		disabled: false
						// 	},
						// 	arrLabel: 'key',
						// 	arrKey: 'value'
						// },
						{
							label: '上下架售罄',
							value: 'shelves',
							type: 'select-arr',
							comp: 'el-select',
							list: 'shelves',
							arrLabel: 'key',
							arrKey: 'value'
						},
						{
							label: '发货日期',
							value: 'deliverDate',
							type: 'date',
							comp: 'el-date-picker',
							bind: {
								format: "yyyy-MM-dd",
								valueFormat: "yyyy-MM-dd"
							}
						},
						{
							label: '商品原价',
							value: 'price',
							type: 'number',
							comp: 'el-input'
						},
						{
							label: '售出价',
							value: 'soldPrice',
							type: 'number',
							comp: 'el-input',
							event: 'showPrice'
						},
						// {
						// 	label: '连抽次数',
						// 	value: 'luckyNum',
						// 	type: 'number',
						// 	comp: 'el-input'
						// },
						{
							label: '回收鸭币',
							value: 'recycleBlance',
							type: 'number',
							comp: 'el-input',
							isHideItem: () => {
								return !this.testFlag
							}
						},
						{
							label: '总的套数',
							value: 'totalPage',
							type: 'number',
							comp: 'el-input',
							bind: {
								disabled: true
							},
						},
						{
							label: '展示的套数',
							value: 'showPage',
							type: 'number',
							comp: 'el-input',
							event: 'showPage'
						},
						{
							label: '优惠卷的有效期',
							value: 'dateDay',
							type: 'number',
							comp: 'el-input'
						},
						{
							label: '回收优惠金',
							value: 'money',
							type: 'number',
							comp: 'el-input'
						},
						{
							label: '玩法说明',
							value: 'note',
							type: 'textarea',
							comp: 'el-input',
							className: 't-form-block'
						},
						{
							label: '封面图',
							value: 'coverImg',
							slotName: 'uploadFile'
						},
						{
							label: '',
							slotName: 'lbpDetailList',
							className: 't-form-block'
						},
					],
					rules: {
						seriesName: [{
							required: true,
							message: '请输入系列名称',
							trigger: 'blur'
						}],
						deliverDate: [{
							required: true,
							message: '请输入发货日期',
							trigger: 'blur'
						}],
						price: [{
							required: true,
							message: '请输入价格',
							trigger: 'blur'
						}],
						soldPrice: [{
							required: true,
							message: '请输入折扣价',
							trigger: 'blur'
						}],
						// othActType: [{
						// 	required: true,
						// 	message: '请至少选择一个分类',
						// 	trigger: 'change'
						// }],
						shelves: [{
							required: true,
							message: '请至少选择一个状态',
							trigger: 'change'
						}],
						totalPage: [{
							required: true,
							message: '请输入总套数',
							trigger: 'blur'
						}],
						showPage: [{
							required: true,
							message: '请输入展示的套数',
							trigger: 'blur'
						}],
						recycleBlance: [{
							required: true,
							message: '请输入回收鸭币数量',
							trigger: 'blur'
						}],
						dateDay: [{
							required: true,
							message: '请输入优惠卷的有效期',
							trigger: 'blur'
						}],
						money: [{
							required: true,
							message: '请输入回收可得优惠金面额',
							trigger: 'blur'
						}],
					},
					//按钮
					operatorList: [],
					// 相关列表
					listTypeInfo: {
						shelves: this.$store.state.menu.dictList.shelves,
						seriesTypes: this.$store.state.menu.dictList.oth_act_type,
						levelTypes:this.$store.state.menu.dictList?this.$store.state.menu.dictList.level_type:[],
						isPresells: [{
								key: "预售",
								value: 1
							},
							{
								key: "现货",
								value: 2
							},
						]
					}
				},
				show:true,
				testFlag: false, //测试
			}
		},
		created() {
			//测试人员
			// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// if (userInfo.name == '测试') {
			//   this.testFlag = true
			// }
		},
		mounted() {},
		methods: {
			//取消展示套数的修改
			cancel() {
				this.dialogVisible = false
				this.butLoading = false
			},
			// 初始化
			init(id,type) {
				console.log("11",id);
				console.log("22",type);
				var that = this
				that.isDrawer = true
				that.goodId = id
				this.findGrade()
				if (id) {
					this.show=false
					that.getDetails(id)
				}
				console.log(this.formOpts.formData)
			},
			//一番赏商品真实等级
			findGrade () {
			  this.$http.get('/grade/findGrade').then(({ data: result }) => {
			    this.gradeConsumes = result.data
				console.log("this.gradeConsumes2",this.gradeConsumes);
			  })
			},
			//关闭弹框
			handleClose() {
				this.show=true
				this.isDrawer = false
				this.resetForm()
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/lbpDetail/findOthActSeriesById?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					if (goodData.lbpDetailList == null || goodData.lbpDetailList == undefined || goodData
						.lbpDetailList == "") {
						goodData.lbpDetailList = []
					}
					this.formOpts.formData = goodData
				})
			},
			// 触发事件
			handleEvent(type, val) {
				switch (type) {
					case 'checkbox':
						break
					case 'showPage':
						if (!this.goodId) {
							this.formOpts.formData.totalPage = val
						}
						break
					case 'showPrice':
						this.formOpts.formData.integral = Number(val) * 2
				}
			},
			// 新增商品
			addDomain() {
				let list = this.formOpts.formData.lbpDetailList
				let levelType = 12
				if (list.length == 1) {
				  levelType = 17
				} else if (list.length > 1) {
				  levelType = list[list.length - 1].levelType + 1
				}
				this.formOpts.formData.lbpDetailList.push({
					boxImg: "",
					ductName: "",
					gradeConsumeId:'',
					id: 0,
					inventory: 0,
					isPresell:'',
					levelType:levelType,
					othActSeriesId: 0,
					referPrice: 0
				})
			},
			removeDomain(index) {
				this.formOpts.formData.lbpDetailList.splice(index, 1)
			},
			editOkSubmit() {
				this.dialogVisible = false
				let goodData = JSON.stringify(this.formOpts.formData)
				// goodData.json = JSON.stringify(goodData.detailsList)
				delete goodData.detailsList
				// goodData.detailsList = JSON.stringify(goodData.detailsList)
				// console.log(goodData.detailsList, 'json')
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/lbpDetail/editOthActSeries'
				} else { //新增
					apiUrl = '/lbpDetail/addOthActSeries'
				}
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: this.goodId ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.butLoading = false
					this.show=true
					this.$emit('refresh')	
									
					this.handleClose()
					
				}).catch(err => {
					this.butLoading = false
				})
			},
			// 提交form表单
			submitForm() {
				console.log("提交");
				this.formOpts.ref.validate((valid) => {
					if (valid) {
						this.butLoading = true
						console.log(77, this.formOpts.formData)
						if (!valid) return
						console.log('最终数据', this.formOpts.formData)
						if (this.formOpts.formData.showPage > 10) {
							this.dialogVisible = true
							return
						} else {
							this.editOkSubmit()
						}
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(
					this.$data.formOpts.formData,
					this.$options.data.call(this).formOpts.formData
				)
				this.$nextTick(() => {
					this.formOpts.ref.clearValidate()
				})
			},
		}
	}
</script>

<style lang="less" scoped>
</style>